.header {
  background-color: var(--color-secondary-400);
  height: 78px;
  display: flex;
  align-items: center;
  grid-area: header;
  padding: 0 32px;
}

.logo {
  height: 36px;
  width: auto;
  max-width: 200px;
}
